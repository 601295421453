import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import MainShop from "./Shop";
import ProductDetail from "./ProductDetail";
import Profile from "../Profile";
import ActiveServices from "../ActiveServices";
import Checkout from "./Checkout";
import { useAuthContext } from "../../contexts/AuthContext";
import TotalOrders from "../TotalOrders";
import DiscountCoupons from "../DiscountCoupons";
import ProfileSettings from "../ProfileSettings";
import AppointService from "../AppointService";
import { Elements } from "@stripe/react-stripe-js";

import { useEffect, useState } from "react";
import axios from "axios";
import { useCartContext } from "../../contexts/CartContext";
import MobileAppCheckout from "./MobileAppCheckout";

const SecureRoute = () => {
  const { isLoggedIn } = useAuthContext();

  return isLoggedIn ? <Outlet /> : <Navigate to="/" replace />;
};

const Shop = () => {
  const { isLoggedIn } = useAuthContext();
  const [secret, setSecret] = useState("");
  const { inclusiveTax, taxAmount, totalPrice } = useCartContext();

  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  return (
    <div className="min-h-screen h-full flex flex-col">
      <Navbar />
      <main className="flex-grow h-full bg-shop-body-bg">
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<MainShop />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/app-checkout" element={<MobileAppCheckout />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          {/* PROTECTED ROUTES */}
          <Route path="/profile" element={<SecureRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/settings" element={<ProfileSettings />} />
            <Route
              path="/profile/active-services"
              element={<ActiveServices />}
            />
            <Route
              path="/profile/active-services/appoint/:id"
              element={<AppointService />}
            />
            <Route path="/profile/total-orders" element={<TotalOrders />} />
            <Route
              path="/profile/discount-coupons"
              element={<DiscountCoupons />}
            />
          </Route>
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default Shop;
