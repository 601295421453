import React, { useState } from "react";
import { CardElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import BookingLoader from '../../data/bookingLoader.json'
import Lottie from "lottie-react";

const stripePromise = loadStripe("pk_test_51P2FxUA28JSbW1KmQMSLZ6Atm0t3vYxkHsl6czGuPv4jM63MWYe6KzWfIZiguWfcNkxZsHtQTwcX61NHA8WdgTc100nwsqLr3Y");

const AppointmentDetailsAndCheckout = ({ userToken, paymentAmount, appointmentDetails, product, serviceId, selectedRoom }) => {
    const baseUrl =
        process.env.REACT_APP_DEVELOPMENT_MODE === "DEVELOPMENT"
            ? process.env.REACT_APP_TESTING_REACT_APP_URL
            : process.env.REACT_APP_URL;
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) return;
        setIsLoading(true);

        try {
            // Create payment intent
            const { data: { clientSecret } } = await axios.post(
                `${baseUrl}/shop/create-payment-intent`,
                { amount: paymentAmount },
                { headers: { Authorization: `Bearer ${userToken}` } }
            );

            // Confirm card payment
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

            if (result.error) {
                toast.error(result.error.message);
                console.error("Payment Error:", result.error);
            } else if (result.paymentIntent.status === "succeeded") {
                toast.success("Payment successful!");
                const data = {
                    ...appointmentDetails,
                    isPaid: true,
                    payment_result: {
                        id: result.paymentIntent.id,
                        status: "Approved",
                        email_address: appointmentDetails.service.email,
                    },
                    product,
                    serviceId,
                    selectedRoom,
                    paymentMethod: "card",
                    price: paymentAmount,
                };

                // Call the API to store appointment data
                await axios.post(
                    `${baseUrl}/user/add-appointment`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                setIsProcessing(true);
                setTimeout(() => {
                    setIsProcessing(false);
                    window.ReactNativeWebView.postMessage("appointment_successful");
                }, 3000);
            }
        } catch (error) {
            toast.error(error.message);
            console.error("Payment Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-6 max-w-2xl mx-auto bg-gray-50 rounded-lg shadow-lg space-y-6">
            {/* Appointment Details */}
            <div className="p-4 bg-white rounded-md shadow">
                <h2 className="text-xl font-semibold text-gray-800">Appointment Details</h2>
                <div className="space-y-4 mt-4">
                    <div>
                        <p className="text-sm text-gray-500">Service:</p>
                        <p className="text-lg text-gray-700">{appointmentDetails.service.name}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Room #:</p>
                        <p className="text-lg text-gray-700">{appointmentDetails.room.name}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Date:</p>
                        <p className="text-lg text-gray-700">{appointmentDetails.date}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Start Time:</p>
                        <p className="text-lg text-gray-700">{appointmentDetails.time}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">End Time:</p>
                        <p className="text-lg text-gray-700">{appointmentDetails.endTime}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Price:</p>
                        <p className="text-lg text-gray-700">${paymentAmount}</p>
                    </div>
                </div>
            </div>

            {/* Checkout Form */}
            <form onSubmit={handleSubmit} className="p-4 bg-white rounded-md shadow">
                <h2 className="text-lg font-semibold mb-4">Payment Form</h2>
                <div className="border-2 border-gray-300 rounded-md p-4 mb-4">
                    <CardElement options={{
                        style: {
                            base: {
                                fontSize: "16px",
                                color: "#424770",
                                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                                "::placeholder": {
                                    color: "#aab7c4",
                                },
                            },
                            invalid: {
                                color: "#9e2146",
                            },
                        },
                    }} />
                </div>
                <button
                    type="submit"
                    disabled={isLoading}
                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 disabled:bg-gray-400"
                >
                    {isLoading ? "Processing..." : `Pay $${paymentAmount}`}
                </button>
            </form>


            {isProcessing && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 w-11/12 max-w-md shadow-lg">
                        <div className="flex justify-center mb-4">
                            <Lottie animationData={BookingLoader} loop style={{ width: 144, height: 144 }} />
                        </div>
                        <h2 className="text-xl font-semibold text-center mb-2 text-black">
                            Processing Your Booking
                        </h2>
                        <p className="text-sm text-center text-gray-500">
                            Please wait a moment while we confirm your session. This won't take long.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

const MobileAppCheckout = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedData = urlParams.get('data');
    const decodedData = JSON.parse(decodeURIComponent(encodedData));
    return (
        <Elements stripe={stripePromise}>
            <AppointmentDetailsAndCheckout
                userToken={decodedData.userToken}
                paymentAmount={decodedData.paymentAmount}
                appointmentDetails={decodedData.appointmentDetails}
                serviceId={decodedData.serviceId}
                product={decodedData.product}
                selectedRoom={decodedData.selectedRoom}
            />
        </Elements>
    );
};

export default MobileAppCheckout;
