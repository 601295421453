import React, { useEffect, useMemo } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Delete, AccountCircle } from "@mui/icons-material";
import { Header } from "../components";
import { useAdminContext } from "../contexts/AdminContext";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useStateContext } from "../contexts/ContextProvider";
const Customers = () => {
  const { customers, fetchAllUsers } = useAdminContext();
  const { currentMode } = useStateContext();
  const navigate = useNavigate();
  const [pagination, setPagination] = React.useState({
    pageIndex: Number(0),
    pageSize: Number(10),
  });

  const [docCount, setDocCount] = React.useState(0);

  const [updatedCustomers, setUpdatedCustomers] = React.useState(customers);

  useEffect(() => {
    const controller = new AbortController();

    const fetchUsers = async () => {
      try {
        const { data, totalCount } = await fetchAllUsers(
          pagination.pageIndex,
          pagination.pageSize
        );
        setUpdatedCustomers(data);
        console.log(
          "🚀 ~ file: Customers.jsx ~ line 108 ~ fetchUsers ~ data",
          data
        );
        console.log(
          "🚀 ~ file: Customers.jsx ~ line 108 ~ fetchUsers ~ totalCount"
        );
        setDocCount(totalCount);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error);
        }
      }
    };

    fetchUsers();

    return () => {
      controller.abort();
    };
  }, [pagination]);

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      header: "Name",
      size: 80,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
    {
      accessorFn: (row) => row.appointments.length,
      accessorKey: "appointments",
      header: "Appointments",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
    {
      accessorFn: (row) => row.userServices.length,
      accessorKey: "userServices",
      header: "Services",
      size: 80,
      // Edit: ({ cell }) => (
      // ),
    },
  ]);

  const handlePaginationChange = (newPagination) => {
    if (
      newPagination.pageIndex !== pagination.pageIndex ||
      newPagination.pageSize !== pagination.pageSize
    ) {
      setPagination(newPagination);
    }
  };

  return (
    <div className="m-2 md:m-10 p2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <Header title="Customers" />
      {updatedCustomers === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <div className="w-full grid">
          <Table
            data={updatedCustomers || []}
            pagination={pagination}
            setPagination={handlePaginationChange}
            docCount={docCount}
            renderRowActions={({ row, table }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip arrow placement="right" title="View Profile">
                  <IconButton
                    onClick={() => {
                      navigate(`/admin/customer/${row.original._id}`);
                    }}
                  >
                    <AccountCircle className="dark:text-white-shade text-black-shade" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            columns={columns}
          />
          {/* {!customers ? <Loading /> : <MaterialReactTable table={table} />} */}
        </div>
      )}
    </div>
  );
};

export default Customers;
